import styled from 'styled-components';

export const WrapperContainer = styled.div`
  position: fixed;
  z-index: 999;
  width: 100vw;
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  width: inherit;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  background-color: ${({ theme: { blogColors } }) => blogColors.primary1};
  color: ${({ theme: { blogColors } }) => blogColors.fontPrimary1};
  height: 6rem;

  @media (${({ theme: { deviceBreakpoints } }) => deviceBreakpoints.desktop}) {
    height: 9rem;
  }
`;

export const LogoContainer = styled.div`
  margin-right: 2.3rem;
  margin-left: 1.7rem;
  > svg {
    width: 11rem;
  }

  @media (${({ theme: { deviceBreakpoints } }) => deviceBreakpoints.tablet}) {
    margin-right: 5.4rem;
    margin-left: 8rem;
    > svg {
      width: 15rem;
    }
  }

  @media (${({ theme: { deviceBreakpoints } }) => deviceBreakpoints.desktop}) {
    margin-right: 6.7rem;
    margin-left: 11.1rem;
    > svg {
      width: 22rem;
    }
  }
`;

export const LabelContainer = styled.span`
  font-family: ${({ theme: { fonts } }) => fonts.merriweather};
  font-weight: bold;
  font-size: 1.4rem;

  @media (${({ theme: { deviceBreakpoints } }) => deviceBreakpoints.tablet}) {
    font-size: 2rem;
  }

  @media (${({ theme: { deviceBreakpoints } }) => deviceBreakpoints.desktop}) {
    font-size: 2.8rem;
  }
`;
