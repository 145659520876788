import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: .4rem;
  top: 6.5rem;

  @media (${({ theme: { deviceBreakpoints } }) => deviceBreakpoints.tablet}) {
    width: 73%;
    height: .7rem;
    top: 6.7rem;
  }

  @media (${({ theme: { deviceBreakpoints } }) => deviceBreakpoints.desktop}) {
    height: 1rem;
    top: 10rem;
  }
`;
