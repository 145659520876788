import { Line } from 'rc-progress';
import * as React from 'react';
import { Container } from './ProgressBar-styled';

type Props = {
  percent: number;
  strokeColor?: string;
  trailColor?: string;
};

const DEFAULT_STROKE_COLOR = '#000';
const DEFAULT_TRAIL_COLOR = '#777';

export const ProgressBar: React.FC<Props> = props => {
  const { percent, strokeColor, trailColor } = props;

  return (
    <Container>
      <Line
        percent={percent}
        strokeColor={strokeColor || DEFAULT_STROKE_COLOR}
        trailColor={trailColor || DEFAULT_TRAIL_COLOR}
        style={{ height: '100%', width: '100%' }}
      />
    </Container>
  );
};
