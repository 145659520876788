import { darken, lighten, transparentize } from 'polished';
import * as React from 'react';
import useBreakpoint from 'use-breakpoint';
import styledComponents, {
  ThemedStyledInterface,
  ThemeProvider as StyledComponentThemeProvider,
} from 'styled-components';
import { useDidUpdate } from '../../../helpers/hooks/useDidUpdate';
import { getCurrentBreakpoint } from './getCurrentBreakpoint';

const size = {
  small: 575,
  mobile: 576,
  tablet: 768,
  laptop: 1366,
  desktop: 1920,
};

const ssrDefaultBreakPoint = 'laptop';

const deviceBreakpoints = {
  small: `max-width: ${size.small}px`,
  mobile: `min-width: ${size.mobile}px`,
  tablet: `min-width: ${size.tablet}px`,
  laptop: `min-width: ${size.laptop}px`,
  desktop: `min-width: ${size.desktop}px`,
};

const mixins = {
  unselectable: `
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;`,
};

export type ThemeMode = 'light' | 'dark';

type ThemeModeColors = {
  background2Opacity: string;

  primary: string;
  primary1: string;
  primary2: string;
  primary3: string;
  primary4: string;
  primary5: string;
  primary6: string;
  primary7: string;
  primary8: string;
  primary9: string;
  primary10: string;
  primary11: string;
  primary12: string;
  primary13: string;
  primary14: string;
  primary15: string;
  primary16: string;

  secondary: string;
  secondary1: string;
  secondary2: string;
  secondary3: string;
  secondary4: string;
  secondary5: string;
  secondary6: string;
  secondary7: string;
  secondary8: string;
  secondary9: string;
  secondary10: string;
  secondary11: string;
  secondary12: string;
  secondary13: string;
  secondary14: string;
  secondary15: string;
  secondary16: string;
  secondary17: string;
  secondary18: string;
  secondary20: string;

  withoutDesign1: string;
  withoutDesign2: string;
  withoutDesign3: string;
  withoutDesign4: string;
  withoutDesign5: string;
  withoutDesign6: string;
  withoutDesign7: string;

  white: string;
  black: string;
  accent: string;
  accent2: string;
  accent3: string;
  accent4: string;
  accent5: string;
  accent6: string;
  disabled: string;
  border: string;
};

const themeModeColors: Record<ThemeMode, ThemeModeColors> = {
  light: {
    background2Opacity: transparentize(.2, '#F3F6F9'),

    primary: '#0052DF',
    primary1: '#084BBE',
    primary2: '#005BF6',
    primary3: '#005EFF',
    primary4: '#80A9EF',
    primary5: '#062861',
    primary6: transparentize(.91, '#80A9EF'),
    primary7: '#FFFFFF',
    primary8: '#005EFF',
    primary9: '#FFFFFF',
    primary10: '#0052DF',
    primary11: '#333',
    primary12: '#0052DF',
    primary13: '#062861',
    primary14: '#70829F',
    primary15: '#084BBE',
    primary16: '#084BBE',

    secondary: '#8898B3',
    secondary1: '#8898B3',
    secondary2: '#BBC7DB',
    secondary3: '#005EFF',
    secondary4: '#EAEFF3',
    secondary5: '#F4F7F8',
    secondary6: '#F3F6F9',
    secondary7: '#BBC7DB',
    secondary8: '#7E8BA1',
    secondary9: '#687997',
    secondary10: transparentize(.7, '#FFFFFF'),
    secondary11: transparentize(.5, '#FFFFFF'),
    secondary12: darken(.4, '#BBC7DB'),
    secondary13: '#344055',
    secondary14: '#FFFFFF',
    secondary15: '#AF9FA5',
    secondary16: '#F2F2F2',
    secondary17: '#8898B3',
    secondary18: '#8898B3',
    secondary20: '#828282',

    // poniższe kolory użyte do stylizacji bez designów
    withoutDesign1: '#233D7A',
    withoutDesign2: '#000000',
    withoutDesign3: '#5A5A5A',
    withoutDesign4: transparentize(.8, '#FFFFFF'),
    withoutDesign5: '#464646',
    withoutDesign6: '#FFFFFF',
    withoutDesign7: '#F5F5F7',

    white: '#FFFFFF',
    black: '#000000',
    accent: '#D70F0F',
    accent2: '#E34B46',
    accent3: '#41D2AB',
    accent4: '#233D7A',
    accent5: '#FFCB00',
    accent6: lighten(.25, '#FFCB00'),
    disabled: '#9FBCC6',
    border: '#E9E9E9',
  },
  dark: {
    background2Opacity: transparentize(.2, '#F3F6F9'),

    primary: '#151E31',
    primary1: '#084BBE',
    primary2: '#005BF6',
    primary3: '#005EFF',
    primary4: '#80A9EF',
    primary5: '#062861',
    primary6: transparentize(.91, '#80A9EF'),
    primary7: '#151E31',
    primary8: '#FFFFFF',
    primary9: '#0052DF',
    primary10: '#0052DF',
    primary11: '#333',
    primary12: '#FFFFFF',
    primary13: '#FFFFFF',
    primary14: '#CBD7EB',
    primary15: '#0052DF',
    primary16: '#FFFFFF',

    secondary: '#808EA7',
    secondary1: '#4B5A80',
    secondary2: '#808EA7',
    secondary3: '#005EFF',
    secondary4: '#354468',
    secondary5: '#354468',
    secondary6: '#101624',
    secondary7: '#BBC7DB',
    secondary8: '#7E8BA1',
    secondary9: '#808EA7',
    secondary10: '#8898B3',
    secondary11: '#8898B3',
    secondary12: lighten(.4, '#BBC7DB'),
    secondary13: '#344055',
    secondary14: '#202A43',
    secondary15: '#828282',
    secondary16: '#4F4F4F',
    secondary17: '#8898B3',
    secondary18: '#354468',
    secondary20: '#828282',

    // kolory stylizacji bez designów
    withoutDesign1: '#8898B3',
    withoutDesign2: '#808EA7',
    withoutDesign3: '#8898B3',
    withoutDesign4: transparentize(.7, '#0f1624'),
    withoutDesign5: '#BBC7DB',
    withoutDesign6: transparentize(0.2, '#202A43'),
    withoutDesign7: '#005BF1',

    white: '#FFFFFF',
    black: '#000000',
    accent: '#D70F0F',
    accent2: '#E34B46',
    accent3: '#41D2AB',
    accent4: '#233D7A',
    accent5: '#A2A8AE',
    accent6: lighten(.25, '#A2A8AE'),
    disabled: '#9FBCC6',
    border: '#E9E9E9',
  },
};

type BlogThemeModeColors = {
  primary1: string;
  primary2: string;
  primary3: string;
  primary4: string;
  primary5: string;
  lightblue1: string;

  fontPrimary1: string;
  fontPrimary2: string;
  fontPrimary3: string;
  fontPrimary4: string;
  fontPrimary5: string;
  fontPrimary6: string;
  fontPrimary7: string;
};

const blogThemeModeColors: Record<ThemeMode, BlogThemeModeColors> = {
  light: {
    primary1: '#0052DF',
    primary2: '#F4F4F4',
    primary3: '#E0E0E0',
    primary4: '#BC9EC1',
    primary5: '#FFF',
    lightblue1: '#F2F6FD',
    fontPrimary1: '#FFF',
    fontPrimary2: '#000',
    fontPrimary3: '#FFF',
    fontPrimary4: '#4F4F4F',
    fontPrimary5: '#0052DF',
    fontPrimary6: '#333333',
    fontPrimary7: '#4F4F4F',
  },
  dark: {
    primary1: '#3772FF',
    primary2: '#596475',
    primary3: '#595959',
    primary4: '#AF9FA5',
    primary5: '#292929',
    lightblue1: '#333333',
    fontPrimary1: '#FFF',
    fontPrimary2: '#FFF',
    fontPrimary3: '#FFF',
    fontPrimary4: '#FFF',
    fontPrimary5: '#FFF',
    fontPrimary6: '#FFF',
    fontPrimary7: '#F2F2F2',
  },
};

export type QueryBreakpointsContextShape = {
  small: boolean;
  mobile: boolean;
  tablet: boolean;
  laptop: boolean;
  desktop: boolean;
};

const initialQueryBreakpointsContext: QueryBreakpointsContextShape = {
  small: false,
  mobile: false,
  tablet: false,
  laptop: false,
  desktop: false,
};

export const theme = {
  size,
  deviceBreakpoints,
  mixins,
  breakpoints: ['576px', '768px', '992px', '1200px'],
  space: [0, '1rem', '2rem', '3rem', '4rem'],
  colors: themeModeColors.light,
  blogColors: blogThemeModeColors.light,
  fonts: {
    variant1: 'Quicksand-Medium',
    merriweather: 'Merriweather-Regular',
    lato: 'Lato-Regular',
  },
  queryBreakpoints: initialQueryBreakpointsContext,
  currentBreakpoint: 'small',
};

export type Theme = typeof theme;

require('./theme.less');

export const getThemeModeColors = (darkMode?: boolean): ThemeModeColors =>
  darkMode ? themeModeColors.dark : themeModeColors.light;

const getBlogThemeModeColors = (darkMode?: boolean): BlogThemeModeColors =>
  darkMode ? blogThemeModeColors.dark : blogThemeModeColors.light;

export type DarkModeHandlers = {
  setDarkMode: (value: boolean) => void;
  setInitialDarkMode: (value: boolean) => void;
};

type DarkModeProviderProps = {
  render: (
    props: {
      darkMode: boolean;
      handlers: DarkModeHandlers;
    },
  ) => React.ReactNode;
};

const useBreakpoints = () => {
  const { breakpoint } = useBreakpoint(size, ssrDefaultBreakPoint);

  const small = breakpoint === 'small';
  const mobile = breakpoint === 'mobile';
  const tablet = breakpoint === 'tablet';
  const laptop = breakpoint === 'laptop';
  const desktop = breakpoint === 'desktop';

  return { small, mobile, tablet, laptop, desktop };
};

export const ThemeProvider = ({ render }: DarkModeProviderProps) => {
  const { small, mobile, tablet, laptop, desktop } = useBreakpoints();
  const [initialDarkMode, setInitialDarkMode] = React.useState(false);
  const [initialDarkModeUpdated, setInitialDarkModeUpdated] = React.useState(false);
  const [darkMode, setDarkMode] = React.useState(false);

  React.useEffect(() => {
    if (initialDarkMode && !initialDarkModeUpdated) {
      setDarkMode(initialDarkMode);
      setInitialDarkModeUpdated(true);
    }
  }, [initialDarkMode]);

  useDidUpdate(() => {
    setInitialDarkModeUpdated(true);
  }, [darkMode]);

  return (
    <StyledComponentThemeProvider
      theme={{
        ...theme,
        colors: getThemeModeColors(darkMode),
        blogColors: getBlogThemeModeColors(darkMode),
        queryBreakpoints: { small, mobile, tablet, laptop, desktop },
        currentBreakpoint: getCurrentBreakpoint(small, mobile, tablet, laptop, desktop),
      }}
    >
      {render({
        darkMode,
        handlers: {
          setDarkMode,
          setInitialDarkMode,
        },
      })}
    </StyledComponentThemeProvider>
  );
};

export const styled: ThemedStyledInterface<Theme> = styledComponents;
