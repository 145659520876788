import * as React from 'react';
import { Container, LabelContainer, LogoContainer, WrapperContainer } from './Header-styled';
import { ProgressBar } from './ProgressBar';

const LogoSvg = require('../../../../../../components/src/assets/Logo.svg').default;

type Props = {
  label?: string;
  progressBar?: {
    percent: number;
    strokeColor?: string;
    trailColor?: string;
  };
};

export const Header: React.FC<Props> = props => {
  const { label, progressBar } = props;

  return (
    <WrapperContainer>
      <Container>
        <LogoContainer>
          <LogoSvg/>
        </LogoContainer>
        <LabelContainer>
          {label}
        </LabelContainer>
      </Container>
      {(progressBar?.percent && progressBar?.percent !== 0) ? (
        <ProgressBar
          percent={progressBar.percent}
          strokeColor={progressBar?.strokeColor}
          trailColor={progressBar?.trailColor}
        />
      ) : null}
    </WrapperContainer>
  );
};
