import * as React from 'react';

export const useDidUpdate = (callback, dependencies) => {
  const hasMount = React.useRef(false);

  React.useEffect(() => {
    if (hasMount.current) {
      callback();
    } else {
      hasMount.current = true;
    }
  }, dependencies);
};
